<template>
  <div class="batch">
    <el-dialog title="批量导入" :visible.sync="dialogVisible" width="483px">
      <!-- <div>{{itemName||"设备"}}</div> -->
      <el-button
        style="padding: 12px;"
        icon="el-icon-download"
        type="primary"
        plain
        @click="exportModel"
        >下载模板</el-button
      >
      <div class="describetip">
        <p>
          · 按照<span style="color: #0f6eff">导入模板.xlsx</span
          >在模板内录入数据；
        </p>
        <p>· 只导入第一张工作表（sheet1)；</p>
        <p>· 请勿修改文件内表格标题，防止导入失败。</p>
      </div>
      <div style="margin-top: 16px">
        <el-upload
          class="upload-demo"
          ref="upload"
          :headers="headers"
          :on-success="successCallback"
          :on-change="fileChange"
          :data="uploadData"
          :on-error="fileError"
          :action="uploadUrl"
          accept=".xls, .xlsx, .csv"
          :limit="1"
          :auto-upload="false"
          drag
        >
          <!-- <i class="el-icon-upload"></i> -->
          <img src="../../assets/img/upload.png" alt="" width="64" style="margin-top:30px;margin-bottom:16px;">
          <div class="el-upload__text">浏览</div>
          <div class="el-upload__text-desc">
            请选择xls、xlsx、csv格式文件
          </div>
        </el-upload>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload">上传</el-button>
      </div>
    </el-dialog>
    <!-- 检查导入加载中 -->
    <el-dialog @close="confirm" title="批量导入" :visible.sync="showCheckImport" width="483px" :close-on-click-modal="false" :show-close="continueText != '' || successText != ''">
      <!-- 加载文案 -->
      <div v-if="!continueText && !successText">
        <p style="text-align: center; color: #344E6E;">正在检查导入文件...</p>
        <div style="text-align: center; margin-top: 15px;margin-bottom:60px;">
          <img src="../../assets/img/loading.gif" alt="" width="64">
        </div>
      </div>
      <!-- 重复 继续导入文案-->
      <div v-if="continueText" style="margin:8px 0;">
        <p style="line-height:20px; color:#01224A;">{{ continueText }}</p>
      </div>
      <div slot="footer" class="dialog-footer" v-if="continueText">
        <el-button type="text" @click="showCheckImport = false">取 消</el-button>
        <el-button type="primary" @click="continueSubmitUpload"
          >继续{{ $t('button.import') }}</el-button
        >
      </div>
      <!-- 上传成功 -->
      <div v-if="successText">
        <div style="text-align: center; margin-top: -4px; margin-bottom: 16px;">
          <img src="../../assets/img/check.png" alt="" width="64">
        </div>
        <p style="text-align: center;margin-bottom: 22px; color:#01224A;line-height:21px;">{{ successText }}</p>
      </div>
      <div slot="footer" class="dialog-footer" v-if="successText">
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { exportExcelNew } from "@/common/js/public";
export default {
  data() {
    return {
      dialogVisible: false,
      headers: {
        "Session-Id": sessionStorage.token,
      },
      showCheckImport: false, // 检查导入弹框
      continueText: "",
      successText: "", // 上传成功文案
      file: null, // 文件流
      syncMbId: "", // 上传成功返回批次
    };
  },
  props: ["uploadUrl", "modelUrl", "uploadData", "itemName"],
  methods: {
    uploadClick() {
      this.$refs.upload.clearFiles();
    },
    fileChange(file) {
      this.file = file.raw;
      if (file.name && !file.response) {
        this.picFlag = true;
      } else if (file.response.state === 1) {
        this.$refs.upload.clearFiles();
        this.picFlag = false;
      }
    },
    fileError(err) {
      // console.log(err)
    },
    submitUpload() {
      if (this.picFlag) {
        this.showCheckImport = true;
        this.$refs.upload.submit();
      } else {
        this.$alert("请选择需要上传的文件", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
    },
    // 继续上传
    continueSubmitUpload() {
      this.continueText = "";
      const fileData = new FormData();
      fileData.append("file", this.file);
      // fileData.append("parkId", this.uploadData.parkId);
      // fileData.append("type", 41);
      fileData.append("removeRepeat", true);
      const opt = {
        method: "post",
        url: this.uploadUrl,
        data: fileData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        success: (res) => {
          this.successCallback(res);
        },
        fail: (res) => {
          this.successCallback(res);
        },
      };
      this.$request(opt);
    },
    // 上传成功回调
    successCallback(res) {
      if (res.state == 0) {
        this.dialogVisible = false;
        this.successText = res.desc;
        // this.syncMbId = res.value.syncMbId;
      } else if (res.state == 41) {
        this.continueText = res.desc;
      } else {
        this.showCheckImport = false;
        this.$alert(res.desc, this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
    },
    confirm() {
      this.continueText = '';
      this.successText = '';
      this.showCheckImport = false;
      this.$emit("success");
      this.clearFiles();
      this.picFlag = false;
    },
    open() {
      this.dialogVisible = true;
    },
    clearFiles() {
      this.$refs.upload && this.$refs.upload.clearFiles();
    },
    exportModel() {
      exportExcelNew(this.modelUrl, this.uploadData);
    },
  },
};
</script>
<style scoped>
.describetip {
  padding: 12px 16px;
  line-height: 24px;
  background: #F7F9FC;
  margin-top: 16px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #677A92;
  line-height: 24px;
  border-radius: 4px;
}
.batch >>> .el-dialog {
  box-shadow: 0px 2px 9px 0px rgba(212, 218, 224, 0.2);
  border-radius: 8px;
}
.batch >>> .el-dialog__header {
    padding: 20px 20px 10px 32px;
}
.batch >>> .el-dialog__title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #01224A;
}
.batch >>> .el-dialog__body {
  padding: 12px 32px;
}
.batch >>> .el-dialog__footer {
  padding: 10px 32px 20px;
}
.batch >>> .el-dialog__headerbtn .el-dialog__close {
  color: #b0b7c3;
  font-size: 21px;
}
.batch >>> .dialog-footer .el-button--primary {
  background-color: #1b82ff;
  border-color: #1b82ff;
  border-radius: 2px;
}
.batch >>> .dialog-footer .el-button--text {
  color: #1b82ff;
  margin-right: 14px;
}
.batch >>> .el-button--primary.is-plain {
    background: #fff;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #01224A;
    border-radius: 4px;
    border: 1px solid rgba(1, 34, 74, 0.12);
}
.batch >>> .el-upload {
  width: 100%;
}

.batch >>> .el-upload-dragger {
  width: 100%;
  min-height: 190px;
  border: 1px dashed rgba(1, 34, 74, 0.12);
  border-radius: 4px;
}

.batch >>> .el-upload-dragger:hover, .batch >>> .el-upload-dragger.is-dragover {
  background: #F7F9FC;
}
.el-upload__text {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #344E6E;
  line-height: 24px;
}
.el-upload__text-desc {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #B2BCC8;
  line-height: 24px;
}
</style>
